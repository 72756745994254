<template>
	<div class="o-row o-row--lg">
		<div class="o-container">
			<div class="c-not-found">
				<h1>404</h1>
				<h2>Deze pagina bestaat niet</h2>
				<img src="~@/assets/images/not-found.svg" alt="Not found" />
			</div>
		</div>
	</div>
</template>

<script src="./NotFound.controller.js"></script>
<style lang="scss" src="./NotFound.styles.scss"></style>
